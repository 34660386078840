<template>
<!-- 国安账单-旧版-视频录制 -->
  <div class="height-100" :class="isRed ? 'redbg' : 'isyello'">
    <div class="card animate__animated animate__fadeIn duration " id="card1">
      <div class="bg_content">
        <div class="content_top">
          <img src="../../assets/img/bg103_3.png" alt="">
        </div>
        <div class="content_middle">
          <h3>2021年</h3>
          <p>国金AI助你揭秘投资账单！</p>
        </div>
        <div class="content_btn" @click="startPlay()">接收账单</div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo103.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="content">
        <p>财主,</p>
        <p>你都忘了吧，这是我们相识1365天的日子~</p>
        <p>你有挂在嘴边的灿烂，</p>
        <p>有埋在心底的无奈，</p>
        <p>但总能找到快乐的点滴。</p>
        <p>国金证券陪你回顾这些天 </p>
        <p>2021年</p>
        <p>···</p>
      </div>
      <div class="content_bg">
        <img src="../../assets/img/bg103_5.png" alt="">
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card3">
      <div class="content_bg"></div>
      <div class="content">
        <p>2021年， </p>
        <p>全年收益35323.57元，</p>
        <p>收益率23.57%，</p>
        <p>战胜了78.71%的投资者，</p>
        <p> 武汉地区排名178名。</p>
      </div>
      <div class="content_bottom">
        <p><span>年度收益率走势</span><span>2021</span></p>
        <p><span>沪深300: 18.52%</span><span>我：23.57%</span></p>
        <div id="chart1"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card4">
      <div class="content_top">
        <img src="../../assets/img/bg103_8.png" alt="">
      </div>
     <div class="content">
       <p>厉害了我的哥~女明星；</p>
       <p>这水平不去当投资顾问可惜了</p>
       <p>比去年多赚了<span class="color_red" id="num1">33745.22</span>元</p>
     </div>
     <div class="content_box none">
       <div>  
         <p>2021年收益</p>
         <div class="bg_progess">
           <p id="num2">35323.57</p>
           <i class="down"></i>
           <!-- <i class="power"></i> -->
         </div>
       </div>
       <div>  
         <p>2020年收益</p>
         <div class="bg_progess">
           <p id="num3">1578.35</p>
           <i class="down"></i>
         </div>
       </div>
       <div>  
         <p>2019年收益</p>
         <div class="bg_progess">
           <p id="num4">12356.57</p>
           <i class="down" ></i>
         </div>
       </div>
       <div>  
         <p>2018年收益</p>
         <div class="bg_progess">
           <p id="num5">-3578</p>
           <i class="down" ></i>
         </div>
       </div>
       <!-- <div class="flot_box">        
          <div class="flot_son">
            <p>
              <span>2021年收益构成</span>
              <i @click="boxClose(0)"></i>
            </p>
            <p class="data">
              <span>股票</span>
              <span>+18753.53</span>
            </p>
            <p class="data">
              <span>基金</span>
              <span>+9572.35</span>
            </p>
            <p class="data">
              <span>债券</span>
              <span>+3005.62</span>
            </p>
            <p class="data">
              <span>理财</span>
              <span>+2413.72</span>
            </p>
          </div>
       </div> -->
     </div>
     <div class="bg_bottom"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5">
     <div class="content">
       <p>这是今年的荣耀时刻， </p>
       <p>跟我一起站上领奖台吧～</p>
       <p class="margin-t-10">最佳操作月份<span class="color_red">08</span>月，</p>
       <p>共盈利<span id="num6" class="color_red">29878.32</span>元</p>
       <p>月收益率<span class="color_red">18.53%</span>。</p>
     </div>
      <div class="bg_content"></div>
      <div class="content_bottom">
        <p><span>年度收益额走势</span><span>2021</span></p>
        <p>年度收益：29878.32</p>
        <div id="chart2"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5_2">
      <div class="content">
        <div class="content_1">
          <p>这个月不太理想，</p>
          <p>坚信强者找因果，</p>
          <p>浅薄信运气～</p>
        </div>
        <div class="content_2 none">
          <p class="content_text2">最衰月份<span class="color_blue">05</span>月，</p>
          <p>共盈利<span class="color_blue" id="num7">-18565.32</span>元，</p>
          <p>月收益率<span class="color_blue">-15.23%</span>。</p>
        </div>

      </div>
      <div class="bg_content"></div>
      <div class="content_bottom">
        <p><span>年度收益额走势</span><span>2021</span></p>
        <p class="color_blue">最衰月份收益：-18565.32</p>
        <div id="chart3"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card6">
      <div class="content">
       <p>这年你获利最多的股票还记得么？</p>
       <p>忙忙碌碌的你可能记不起来了， </p>
       <p>试着选出这支让你跳跃的股票吧～</p>
      </div>
      <div class="bg_1"></div>
      <div class="bg_2"></div>
      <div class="content_box">
        <p  :class="{'active' : (card6Num == 0) }">中材科技</p>
        <p  :class="{'active' : (card6Num == 1) }">比亚迪</p>
        <p  :class="{'active' : (card6Num == 2) }">泸州老窖</p>
        <p  :class="{'active' : (card6Num == 3) }">迈瑞医疗</p>
        <p  :class="{'active' : (card6Num == 4) }">宁德时代</p>
        <p  :class="{'active' : (card6Num == 5) }">通威股份</p>
        <p  :class="{'active' : (card6Num == 6) }">隆基股份</p>
        <p  :class="{'active' : (card6Num == 7) }">光伏ETF</p>
        <p  :class="{'active' : (card6Num == 8) }">恩捷股份</p>
        <p  :class="{'active' : (card6Num == 9) }">招商银行</p>
      </div>
      
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7">
      <div class="content">
       <p>竟然答对了</p>
       <p>看来这只股已经俘获了你的心 </p>
       <p>让我们继续吧～</p>
      </div>
      <div class="bg_1"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7_2">
      <div class="content">
        <p>回答错误</p>
        <p>我说吧，你一定是太忙了～</p>
        <p>让小宝带你去看看吧！</p>
      </div>
      <div class="bg_1"></div>
      <div class="bg_btn" @click="lookYes()">查看正确答案</div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8">
      <div class="header">年度赚钱最多的股票</div>
      <div class="top">TOP3</div>
      <div class="content">
        <div>
          <p><span></span><span>招商银行</span></p>
          <p id="num8">12131.57</p>
        </div>
        <div>
          <p><span></span><span>明阳智能</span></p>
          <p id="num9">7530.25</p>
        </div>
        <div>
          <p><span></span><span>新雷能</span></p>
          <p id="num10">6352.53</p>
        </div>
      </div>
      <div class="bg_1"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8_2">
      <div class="header">年度亏损最多的股票</div>
      <div class="top">TOP3</div>
      <div class="content content_down">
        <div>
          <p><span></span><span>埃斯顿</span></p>
          <p id="num11">-9763.35</p>
        </div>
        <div>
          <p><span></span><span>华夏航空</span></p>
          <p id="num12">-8320.36</p>
        </div>
        <div>
          <p><span></span><span>恒生电子</span></p>
          <p id="num13">-5730.2</p>
        </div>
      </div>
      <div class="bg_1"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card9">
      <div class="content">
       <p>你在21年合计交易股票<span class="color_red" id="num14">832</span>次，</p>
       <p>盈利次数<span class="color_red" id="num15">539</span>次，</p>
       <p>年度交易成功率<span class="color_red" id="num16">64.78</span>%，</p>
       <p>新股申购<span class="color_red" id="num17">1275</span>次，</p>
       <p>中签<span class="color_red">05</span>次，运气爆棚</p>
      </div>
      <div class="bg_1"></div>
      <!-- <div class="bg_2">
        <img src="../../assets/img/bg103_16.png" alt="">
      </div> -->
      <div class="bg_3"></div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card10">
      <div class="content">
       <p>你的年度关键字</p>
       <p class="line"></p>
       <p>“金牌操盘手”</p>
       <p>你的账户收益率在2021年战胜了97.89%的股民，操作水平直逼王者，简直神之一手～</p>
       <p class="bottom">
         <img src="../../assets/img/bg103_18.png" alt="">
       </p>
      </div>
      <div class="person_photo">
        <img src="../../assets/img/bg103_19.png" alt="">
      </div>
      <div class="btn_share btn">立即分享</div>
      <div class="btn_save btn">保存图片</div>     
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep , GetParams2,numberRoll } from "../../utils/common";
import ApiServe from '@/api/index'
export default {
  name: "zhangdan",
  data() {
    return {
      isRed:false,
      card6Num:0,
      id:'',
      model_id:null,
      model_name:"",
      model_category:'',
      ip:'',
      port:'',
      offset_x:'',
      offset_y:'',
      width:"",
      height:'',
      source:''
    };
  },
  created() {
    this.id = GetParams2().id || null
    this.model_id = GetParams2().model_id || 200
    this.model_name = GetParams2().model_name || null
    this.model_category = GetParams2().model_category || null
    this.ip = GetParams2().ip || null
    this.port = GetParams2().port || null
    this.offset_x = GetParams2().offset_x || null
    this.offset_y = GetParams2().offset_y || null
    this.width = GetParams2().width || null
    this.height = GetParams2().height || null
    this.source = GetParams2().source || null
  },
  computed:{
    newIpPort(){
      if(this.source == 'ali'){
        return 'https://recordvideoali.wedengta.com'
      }else{
        return 'https://recordvideo.wedengta.com'
      }
    }
  },
  async mounted(){
     await sleep(5000)
      if (this.ip && this.port) {
        const params = {
            ip:this.ip,
            port:this.port,
            offset_x:this.offset_x,
            offset_y:this.offset_y,
            width:this.width,
            height:this.height
            }
            ApiServe.recordstartPc(this.newIpPort,params).then(({code}) => {
            
            }).catch((err)=>{
                ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
            });
            this.startPlay();
        } else {
            alert('没获取到ip,port')
            this.startPlay();
        }
  },
  methods: {
    loadChart1(){
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.0.03",
            "2021.12.30",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [12, 23.57],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
          },
          {
            data: [10, 18.52],
            type: "line",
            color: '#3173EF',
            showSymbol: false,
          },
        ],
      };
      myChart.setOption(option);
    },
    loadChart2(){
      let myChart = this.$echarts.init(document.getElementById("chart2"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.01",
            "2021.03",
            "2021.05",
            "2021.07",
            "2021.08",
            "2021.010",
            "2021.12",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 2000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [1200,1400,600,900,1800,1100,1000],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                colorStops: [{
                  offset: 0,  color: '#FF4A40'  // 100% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255, 74, 64, 0)' //   0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#9aea51', //线条颜色
            smooth: true //线条平滑
          },
        ],
      };
      myChart.setOption(option);
    },
    loadChart3(){
      let myChart = this.$echarts.init(document.getElementById("chart3"));
      let option = {
        xAxis: {
          type: "category",
          data: [
            "2021.01",
            "2021.03",
            "2021.05",
            "2021.07",
            "2021.08",
            "2021.010",
            "2021.12",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E0AD94",
            },
          },
          axisLabel: {
            color: "#E0AD94",
          },
        },
        grid: {
          top:20,
          left: 30,
          bottom: 20,
          right:10
        },
        animationDuration: 2000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E0AD94",
            },
          },
        },
        series: [
          {
            data: [1200,1400,600,900,1800,1100,1000],
            type: "line",
            color: '#FF4A40',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                colorStops: [{
                  offset: 0,  color: '#FF4A40'  // 100% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255, 74, 64, 0)' //   0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#9aea51', //线条颜色
            smooth: true //线条平滑
          }
        ],
      };
      myChart.setOption(option);
    },
    playCard1(suspend){
      return new Promise(async (resolve, reject) => {
        await sleep(4000)
        $("#card1").addClass("none");
        resolve();
      });
    },
    playCard2(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card2").removeClass("none");
        await sleep(1000)
        $("#card2 .content p").eq(0).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(1).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(2).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(3).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(4).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(5).addClass('animate__animated animate__backInLeft');
         await sleep(1000)
        $("#card2 .content p").eq(6).addClass('animate__animated animate__backInLeft');
        await sleep(4000);
        $("#card2").addClass("none");
        resolve();
      });
    },
    playCard3(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card3").removeClass("none");
        await sleep(500)
        this.loadChart1()
        $("#card3 .content p").eq(0).addClass('animate__animated animate__backInLeft');
         await sleep(500)
        $("#card3 .content p").eq(1).addClass('animate__animated animate__backInLeft');
         await sleep(500)
        $("#card3 .content p").eq(2).addClass('animate__animated animate__backInLeft');
         await sleep(500)
        $("#card3 .content p").eq(3).addClass('animate__animated animate__backInLeft');
         await sleep(500)
        $("#card3 .content p").eq(4).addClass('animate__animated animate__backInLeft');
        await sleep(3500);
        $("#card3").addClass("none");
        resolve();
      });
    },
    playCard4(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card4").removeClass("none");
        $("#card4 .content").addClass('animate__animated animate__backInLeft');
        await sleep(1500);
        numberRoll("num1", 33745.22, 2,2);
        $("#card4 .content_box").removeClass('none')
        $("#card4 .content_box").addClass('animate__animated animate__backInLeft');
        await sleep(1500)
        numberRoll("num2", 35323.57, 2);
        await sleep(500)
        numberRoll("num3", 1578.35, 2);
        await sleep(500)
        numberRoll("num4", 12356.57, 2);
        await sleep(500)
        numberRoll("num5", -3578, 2);
        await sleep(2500);
        $("#card4").addClass("none");
        resolve();   
      });
    },
    playCard5(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card5").removeClass("none");
         $("#card5 .content").addClass('animate__animated animate__backInLeft');
         await sleep(1500)
        numberRoll("num6", 29878.32, 2);
        await sleep(1000)
        this.loadChart2()
        await sleep(2500);
        $("#card5").addClass("none");
        resolve();    
      });
    },
    playCard5_2(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card5_2").removeClass("none");
        $("#card5_2 .content_1").addClass('animate__animated animate__backInLeft');
        await sleep(1000)
        $("#card5_2 .content_2").removeClass('none')
        $("#card5_2 .content_2").addClass('animate__animated animate__backInLeft');
        await sleep(1500)
        numberRoll("num7", -18565.32, 2);
        this.loadChart3() 
        await sleep(2500);
        $("#card5_2").addClass("none");
        resolve();   
      });
    },
    playCard6(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card6").removeClass("none");
        $("#card6 .content").addClass('animate__animated animate__backInLeft');
        await sleep(1000)
        this.card6Num = 1
        await sleep(500)
        this.card6Num = 3
        await sleep(500)
        this.card6Num = 9
        await sleep(1000);
        $("#card6").addClass("none");
        resolve();   
      });
    },
    playCard7(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card7").removeClass("none");
        await sleep(5000);
        $("#card7").addClass("none");
        resolve();   
      });
    },
    playCard8(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card8").removeClass("none");
        $("#card8 .content").addClass('animate__animated animate__backInLeft');
        await sleep(1500)
        numberRoll("num8", 12131.57, 2);
        await sleep(1000)
        numberRoll("num9", 7530.25, 2);
        await sleep(1000)
        numberRoll("num10", 6352.53, 2);
        await sleep(1500);
        $("#card8").addClass("none");
        resolve();   
      });
    },
    playCard8_2(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card8_2").removeClass("none");
        $("#card8_2 .content").addClass('animate__animated animate__backInLeft');
        await sleep(1500)
        numberRoll("num11", -9763.35, 2);
        await sleep(1000)
        numberRoll("num12", -8320.36, 2);
        await sleep(1000)
        numberRoll("num13", -5730.2, 2);
        await sleep(1500);
        $("#card8_2").addClass("none");
        resolve();   
      });
    },
    playCard9(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card9").removeClass("none");
         $("#card9 .content").addClass('animate__animated animate__backInLeft');
        await sleep(1500)
        numberRoll("num14", 832, 0);
        await sleep(1000)
        numberRoll("num15", 539, 0);
        await sleep(1000)
        numberRoll("num16", 64.78, 2);
        await sleep(1000)
        numberRoll("num17", 1275, 0);
        await sleep(2500);
        $("#card9").addClass("none");
        resolve();    
      });
    },
    playCard10(suspend){
      return new Promise(async (resolve, reject) => {
        $("#card10").removeClass("none");
         $("#card10 .content").addClass('animate__animated animate__zoomIn');
         await sleep(6000)
        resolve();
      });
    },
    async startPlay(){
        await this.playCard1() 
        await this.playCard2()
        await this.playCard3()
        await this.playCard4()
        await this.playCard5()
        await this.playCard5_2()
        await this.playCard6()
        await this.playCard8()
        await this.playCard8_2()
        await this.playCard9()
        await this.playCard10()
        if (this.ip && this.port) {
            this.endRecord();
        }
    },
    endRecord(){
      let audiosOrderList =  [
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-af83c712.mp3',
          timeIndex: 1,
          audioLen: 2, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-885de563.mp3',
          timeIndex: 4,
          audioLen: 10, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-117288fa.mp3',
          timeIndex: 15,
          audioLen: 5, 
        },{
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-dcd7174e.mp3',
          timeIndex: 21,
          audioLen: 4, 
        },{
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-d1665540.mp3',
          timeIndex: 28,
          audioLen: 4, 
        },{
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-b50b09ca.mp3',
          timeIndex: 33,
          audioLen: 4, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-ac680acf.mp3',
          timeIndex: 38,
          audioLen: 2, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-b2caaaad.mp3',
          timeIndex: 41,
          audioLen: 2, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-e3c55e61.mp3',
          timeIndex: 46,
          audioLen: 2, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-ed04ebff.mp3',
          timeIndex: 51,
          audioLen: 6, 
        },
        {
          audioLink:'https://res.idtcdn.com/Cube/video-config/con-0d3b31ab.mp3',
          timeIndex: 58,
          audioLen: 5, 
        }
      ]
      const data = {
        'id':this.id, 
        'model_id':this.model_id,
        'videoTotalTime':64,
        'videmBgm':"http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/yinhe/RaisingMeHigher.mp3",
        'audiosOrderList':audiosOrderList
      }
      ApiServe.recordstopPc(this.newIpPort,{ip:this.ip,port:this.port}).then(()=>{
        ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data).then(()=>{
           ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        }).catch(()=>{
          ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        })
      }).catch(()=>{
        ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
      })
    },
  }
};
</script>
<style lang="less" scoped>
.logo-box{
  bottom: 3.5%;
  width: 8.3rem;
}
.card{
  color: #fff;
  background-color: transparent;
  overflow: hidden;
  font-size: 1rem;
}
.redbg{
  background: url("../../assets/img/bg103_4.png") no-repeat center center;
  background-size: cover;
}
.isyello{
  background: url("../../assets/img/bg103_7.png") no-repeat center center;
  background-size: cover;
}
.color_red{
  color: #E9560D;
}
.color_blue{
  color: #2F932F;
}
.screen{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  .back{
    position: absolute;
    left: 2rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    background: url("../../assets/img/back.png") no-repeat center center;
    background-size: cover;
  }
}
.mengceng{
  width: 100%;
  height: 4rem;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
}
#card1 {
  background: url("../../assets/img/bg103_1.png") no-repeat center center;
  background-size: cover;
  .bg_content{
    height: 80%;
    background: url("../../assets/img/bg103_2.png") no-repeat center center;
    margin-top: 4rem;
    background-size: cover;
    overflow: hidden;
    .content_top{
      width: 22.4rem;
      height: 5.1rem;
      margin: 5rem auto 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .content_middle{
      width: 29rem;
      height: 10.6rem;
      margin: 6rem auto;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
      color: #493E38;
      h3{
        font-size: 4rem;
        margin-bottom: 1rem;
      }
    }
    .content_btn{
      width: 24rem;
      height: 5.6rem;
      text-align: center;
      line-height: 5.6rem;
      border-radius:10rem ;
      background-color: #E9560D ;
      color: #fff;
      margin: auto;
      font-size: 2.2rem;
    }
  }
}
#card2{
  .content{
    padding: 4rem 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
  }
  .content_bg{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24.9rem;
    img{
      width: 100%;
      height: auto;
    }
  }
}
#card3{
  .content_bg{
    position: absolute;
    width: 100%;
    top: 3.3rem;
    bottom: 0;
    background: url("../../assets/img/bg103_6.png") no-repeat center center;
    background-size: cover;
    z-index: -1;
  }
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 55%;
  }
  .content_bottom{
    width: 30rem;
    height: 18.6rem;
    margin: 1rem auto 0;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      >span:nth-of-type(1){
        color: #3173EF;
      }
      >span:nth-of-type(2){
        color: #FF4A40;
        margin-left: 4.3rem;
      }
    }
    #chart1{
      width: 100%;
      height: 14rem;
    }
  }
}
#card4{
  .content_top{
    position: absolute;
    width: 100%;
    height: 5.9rem;
    top: 0;
    img{
      width: 100%;
      height: auto;
    }
  }
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
  }
  .content_box{
    padding: 0 2rem;
    margin-top: 2rem;
    position: relative;
    >div{
      margin-top: 1.6rem;
    }
    .bg_progess{
      height: 2.9rem;
      line-height: 2.9rem;
      background: rgba(255,255,255,0.24);
      border-radius: .4rem;
      margin-top: 0.6rem;
      position: relative;
      >p{
        text-align: right;
        width: 80%;
        height: 100%;
        background: #E9560D;
        padding-right: .8rem;
        border-radius: .4rem;
      }
      i.down{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
      i.power{
        width: 5rem;
        height: 5rem;
        background: url("../../assets/img/bg103_25.gif") no-repeat center center;
        background-size: cover;
        position: absolute;
        right: -1rem;
        top: -1rem;
        opacity: 0.4;
      }
    }
    >div:nth-of-type(4){
      .bg_progess{
        >p{
          background: #2F932F;
        }
      }
    }
    .flot_box{
      position: absolute;
      left: 2rem;
      top: 0;
      width: 30rem;
      height: 23rem;
      border: 2px solid #FF9F01;
      border-radius: 1rem;
      background: #FFF4E0;
      color: #000;
      padding: 1rem;
      z-index: 10;
      p{
        display: flex;
        justify-content: space-between;      
      }
      p.data{
        margin-top: 3rem;
        span:nth-of-type(2){
          color: #E9560D;
        }
      }
      p:first-of-type{
        i{
          width: 2.1rem;
          height: 2.1rem;
          background: url("../../assets/img/bg103_20.png") no-repeat center center;
          background-size: cover;
        }
        
      }
    }
    .flot_box4{
      p.data{
        span:nth-of-type(2){
          color: #2F932F;
        }
      }
    }
  }
  .bg_bottom{
    position: absolute;
    width: 100%;
    height: 23.8rem;
    bottom: 0;
    background: url("../../assets/img/bg103_9.png") no-repeat center center;
    background-size: cover;
    z-index: -1;
  }
  .bg_tougu{
    position: absolute;
    bottom: 32%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
    z-index: 1;
    // background: url("../../assets/img/bg103_10.png") no-repeat center center;
    // background-size: cover;
  }
  .screen{
    background: url("../../assets/img/bg103_23.png") no-repeat center center;
    background-size: cover;
  }
}
#card5{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
    position: relative;
  }
  .bg_content{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 44.1rem;
    background: url("../../assets/img/bg103_11.png") no-repeat center center;
    background-size: cover;
  }
  .content_bottom{
    // width: 30rem;
    height: 18.6rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      color: #FF4A40;
    }
    #chart2{
      width: 100%;
      height: 14rem;
    }
  }
  .bg_kuisun{
    position: absolute;
    top: 6rem;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
}
#card5_2{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 18%;
    .content_text2{
      margin-top: 6rem;
    }
  }
  .bg_content{
    position: absolute;
    width: 19.6rem;
    top: 1rem;
    right: 2.6rem;
    height: 23.4rem;
    background: url("../../assets/img/bg103_12.png") no-repeat center center;
    background-size: cover;
  }
  .bg_kuisun{
    position: absolute;
    bottom: 52%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .content_bottom{
    // width: 30rem;
    height: 18.6rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    background: #F5F2EA;
    border-radius: 1rem;
    border: 1px solid #FFF8E6;
    color: #252525;
    padding: 1rem;
    >p:first-of-type{
      display: flex;
      justify-content: space-between;
      >span:first-of-type{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    >p:nth-of-type(2){
      margin-top: .4rem;
      font-size: 1rem;
      // color: #FF4A40;
    }
    #chart3{
      width: 100%;
      height: 14rem;
    }
  }
}
#card6{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 16%;
  }
  .bg_1{
    position: absolute;
    width: 100%;
    top: 0;
    height: 5.9rem;
    background: url("../../assets/img/bg103_8.png") no-repeat center center;
    background-size: cover;
  }
  .bg_2{
    position: absolute;
    width: 26.1rem;
    bottom: 0;
    left: 2.5rem;
    height: 29.4rem;
    background: url("../../assets/img/bg103_13.png") no-repeat center center;
    background-size: cover;
  }
  .content_box{
    position: relative;
    p{
      position: absolute;
      padding: 0.5rem 0;
      width: 7.3rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 2rem;
    }
    p.active{
      background: #FFE98B;
      color: #E9560D;
    }
    p:nth-of-type(1){
      left: 13.1rem;
      top: 3rem;
    }
    p:nth-of-type(2){
      left: 3.2rem;
      top: 5rem;
    }
    p:nth-of-type(3){
      right: 4rem;
      top: 5rem;
    }
    p:nth-of-type(4){
      left: 4.2rem;
      top: 9rem;
    }
    p:nth-of-type(5){
      right: 5.5rem;
      top: 12rem;
    }
    p:nth-of-type(6){
      left: 2.7rem;
      top: 13rem;
    }
    p:nth-of-type(7){
      left: 14.4rem;
      top: 14.5rem;
    }
    p:nth-of-type(8){
      left: 8rem;
      top: 18rem;
    }
    p:nth-of-type(9){
      right: 7.1rem;
      top: 18rem;
    }
    p:nth-of-type(10){
      right: 13.3rem;
      top: 8.5rem;
    }
  }
}
#card7,#card7_2{
  .content{
    width: 23rem;
    height: 13rem;
    line-height: 4.2rem;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 45%;
    >p:nth-of-type(1){
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .bg_1{
    position: absolute;
    width: 16.8rem;
    top: 6rem;
    left: 4.3rem;
    height: 18.9rem;
    background: url("../../assets/img/bg103_13.png") no-repeat center center;
    background-size: cover;
  }
  .bg_btn{
    width: 13.3rem;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    position: absolute;
    right: -20%;
    transform: translateX(50%);
    bottom: 23%;
    background: linear-gradient(171deg, #FFBB0A 0%, #FF9D00 100%);
    color: #000;
    border-radius: 26px;
  }
}
#card8,#card8_2{
  .header{
    font-size: 3.2rem;
    margin: 6rem 0 1rem 2rem;
  }
  .top{
    margin-left: 2rem;
  }
  .content{
    >div{
      width: 30rem;
      padding: 0 1rem;
      height: 4.2rem;
      margin:4rem 0 0 2rem ;
      border-radius: 2rem;
      background: #E9560D;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .content_down{
    >div{
      background: #2F932F;
    }
  }
  .bg_1{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 20.5rem;
    background: url("../../assets/img/bg103_14.png") no-repeat center center;
    background-size: cover;
  }
  .bg_kuisun{
    position: absolute;
    bottom: 32%;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .screen{
    background: url("../../assets/img/bg103_21.png") no-repeat center center;
    background-size: cover;
  }
}
#card9{
  .content{
    padding: 0 2rem;
    font-size: 1.6rem;
    line-height: 4rem;
    margin-top: 16%;
    span{
      display: inline-block;
      min-width: 75px;
    }
    p:last-of-type{
      span{
        min-width: 40px;
      }
      
    }
  }
  .btn_shengou{
    position: absolute;
    top: 22.5rem;
    right: -10rem;
    width: 9rem;
    height: 3rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 3rem;
    background: #FFBB0A;
    color: #000;
  }
  .bg_1{
    position: absolute;
    width: 5.1rem;
    right: 2.5rem;
    top: 11.7rem;
    height: 10.7rem;
    background: url("../../assets/img/bg103_15.png") no-repeat center center;
    background-size: cover;
  }
  .bg_2{
    position: absolute;
    width: 14.5rem;
    right: -10rem;
    top: 27.5rem;
    height: 5.5rem;
    img{
      width: 100%;
      height: auto;
    }
  }
  .bg_3{
    position: absolute;
    width: 30.8rem;
    left: 2.6rem;
    bottom: 6.3rem;
    height: 26.5rem;
    background: url("../../assets/img/bg103_17.png") no-repeat center center;
    background-size: cover;
  }
  .shengu{
    background: url("../../assets/img/bg103_22.png") no-repeat center center;
    background-size: cover;
  }
  .licai{
    background: url("../../assets/img/bg103_24.png") no-repeat center center;
    background-size: cover;
  }
}
#card10{
  .content{
    width: 32rem;
    height: 45.4rem;
    background: #FAF4F4;
    margin: 4.5rem auto 0;
    border-radius: 4.2rem 0px 4.2rem 0px;
    position: relative;
    color: #462F24;
    >p:nth-of-type(1){
      position: absolute;
      left: 50%;
      width: 18.6rem;
      transform: translateX(-50%);
      top: 6rem;
      font-size: 2.6rem;
      z-index: 10;
    }
    .line{
      position: absolute;
      left: 50%;
      width: 18.6rem;
      height: 1rem;
      transform: translateX(-50%);
      top: 8.5rem;
      background: #FFA54D;
      z-index: 1;
    }
    >p:nth-of-type(3){
      position: absolute;
      width: 100%;
      text-align: center;
      top: 16.6rem;
      font-size: 4rem;
      color: #483112;
    }
    >p:nth-of-type(4){
      position: absolute;
      width: 27.8rem;
      left: 2rem;
      top: 24.1rem;
      line-height: 2.1rem;
      color: #E9560D;
    }
    .bottom{
      width: 100%;
      height: 9.6rem;
      position: absolute;
      bottom: 0;
      background: #FFFDFD;
      border-radius: 0px 0px 4.2rem 0px;
      text-align: center;
      img{
        width: 28.2rem;
        height: auto;
        margin-top: 1.9rem;
      }
    }
    
  }
  .person_photo{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 1.5rem;
      width: 6rem;
      height: 6rem;
      img{
        width: 100%;
        height: auto;
      }
    }
  .btn{
    position: absolute;
    width: 13.3rem;
    height: 4.2rem;
    text-align: center;
    line-height: 4.2rem;
    border-radius: 2.6rem;
    color: #000;
  }
  .btn_share{
    left: 2rem;
    bottom: 5.6rem;
    background: #FFE1B1;
  }
  .btn_save{
    right: 2rem;
    bottom: 5.6rem;
    background: #FF9D00;
  }
}
</style>